import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";

const LogoImage = styled.img`
  max-width: 10%;
  margin: 5px 10px 0px 0px;
`

const Logo = () => (
  <StaticQuery query={graphql`
    {
      allWordpressWpLogo {
        edges {
          node {
            url {
              source_url
            }
          }
        }
      }
    }
  `} render={props => (
      <LogoImage src={props.allWordpressWpLogo.edges[0].node.url.source_url} alt="Logo" />
    )} />
);
export default Logo